import { useEffect, useRef, useState } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { request } from "./request";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import "./serendipity.css";
import { Button, ImageListItemBar, Skeleton } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <span>{children}</span>
        </Box>
      )}
    </div>
  );
}

const handleClickAdd = () => {
  window.open('https://qinglanspace.feishu.cn/share/base/form/shrcnme8ZcIDeU5t8oCyciIl3fc');
}

export default function App() {

  useEffect(() => {
    document.title = '行动创造美好｜青榄社';
  }, []);

  const ref = useRef(null);

  const options = {
    // Fullscreen: {
    //   autoStart: true,
    // },
    Carousel: {
      infinite: true,
    },
    Slideshow: {
      playOnStart: true,
      timeout: 5000,
    },
    Images: {
      zoom: false,
    },
    Toolbar: {
      enabled: true,
      items: {
        write: {
          tpl: `<button class="f-button" style="width: fit-content;
            background-color: #323232;
            padding: 10pt;">✏️留下美好</button>`,
          click: handleClickAdd,
        },
      },
      display: {
        right: ["write", "close"],
      },
    },
    Thumbs: false
  };

  const handleClick = (item: any) => {
    NativeFancybox.close();
    const fancybox = new NativeFancybox(
      [
        {
          src: `https://cos.qinglan.space/${item.files}`,
          caption: `<div><div>${item.title}</div><div>“${item.message}”</div></div>`,
        },
      ] as any,
      { ...options },
    );
  }

  const [curTab, setTab] = useState(1)

  const [itemData, setDate] = useState<any>([]);
  const itemDataRef = useRef(itemData);
  useEffect(() => {
    itemDataRef.current = itemData;
  }, [itemData]);

  useEffect(() => {
    setDate([])
    fetchData(1)
  }, [curTab])

  const fetchData = async (page: number) => {
    const response = await request(`/serendipity/list?type=${curTab}&page=${page}`);
    const data = await response.json();

    setDate((prev: any) => {
      if (prev?.page === data.page) return prev;
      return ({
        page: data.page,
        list: prev && prev?.list ? [...prev.list, ...data.list] : data.list,
        end: data.list.length <= 0,
      })
    });
  }

  // const handleScroll = (e: { target: { scrollHeight: number; scrollTop: number; clientHeight: number; }; }) => {
  //   const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
  //   if (bottom) {
  //     // Handle bottom reached
  //     console.log('bottom reached')
  //     if (itemDataRef.current.end) return;
  //     fetchData(parseFloat(itemDataRef.current.page) + 1);
  //   }
  // };

  // useEffect(() => {
  //   const element = ref.current as any;
  //   if (element && element.addEventListener)
  //     element.addEventListener('scroll', handleScroll);

  //   return () => {
  //     if (element && element.removeEventListener)
  //       element.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  const [loaded, setLoaded] = useState<{ [key: number]: boolean }>({});
  const handleImageLoad = (id: number, last: boolean) => {
    setLoaded(prev => ({ ...prev, [id]: true }));
    if (last) {
      if (itemDataRef.current.end) return;
      fetchData(parseFloat(itemDataRef.current.page) + 1);
    }
  };

  return (
    <div className="App serendipity-bg">
      <div className="serendipity-logo"></div>
      <div
        style={{
          width: "100vw",
          display: "-webkit-flex",
          justifyContent: "center",
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Tabs
            value={curTab}
            onChange={(_, val) => { setTab(val) }}
            centered
            textColor="primary"
          >
            <Tab style={{ color: '#ffc500' }} value={1} label="#好好爱自己" color="#ffc500" />
            <Tab style={{ color: '#ffc500' }} value={2} label="#爱身边人" color="#ffc500" />
            <Tab style={{ color: '#ffc500' }} value={3} label="#爱这个地方" color="#ffc500" />
          </Tabs>
          <CustomTabPanel value={1} index={1}>
            <div ref={ref} style={{ height: '70vh', overflow: 'auto' }}>
              {itemData?.list && itemData?.list.length > 0 &&
                <ImageList variant="masonry" cols={1} gap={8}>
                  {itemData?.list?.map((item: any, idx: number) => (
                    <ImageListItem key={item.id} onClick={() => { handleClick(item) }}>
                      {!loaded[item.id] && <Skeleton animation="wave" variant="rectangular" height={120} />}
                      <img
                        srcSet={`https://cos.qinglan.space/${item.files}${(item.files as string).toLocaleLowerCase().indexOf('.mov') ? '' : '?imageMogr2/thumbnail/356x'}`}
                        src={`https://cos.qinglan.space/${item.files}${(item.files as string).toLocaleLowerCase().indexOf('.mov') ? '' : '?imageMogr2/thumbnail/356x'}`}
                        alt={item.title}
                        loading={idx === itemData?.list?.length - 1 ? "lazy" : "eager"}
                        onLoad={() => handleImageLoad(item.id, itemData?.list && idx === itemData?.list?.length - 1)}
                      />
                      <ImageListItemBar
                        title={item.title}
                        subtitle={item.message}
                      />
                    </ImageListItem>
                  ))}
                </ImageList>}
            </div>
          </CustomTabPanel>
        </Box>

      </div>
      <div className="serendipity-bottom">
        <div className="serendipity-bottom-tab"></div>
        <div className="serendipity-bottom-button">
          <Button style={{ backgroundColor: '#ffc500' }} type="button" variant="contained" color="primary" size="large">+</Button>
        </div>
        <div className="serendipity-bottom-logo" onClick={handleClickAdd}></div>
      </div>
    </div>
  );
}
