import "@fancyapps/ui/dist/fancybox/fancybox.css";
import "./message.css";

import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

export default function App() {

    const navigate = useNavigate();
    const itemData = [
        { type: '/birthday', title: '送你一个生日祝福' },
        { type: '/message', title: '我想对你说......' },
        { type: '/serendipity', title: '遇见美好小事' },
    ]


    return (
        <div>
            <div
                style={{
                    display: "-webkit-flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    <nav aria-label="main mailbox folders">
                        <List>
                            {itemData.map((item) => (
                                <ListItem disablePadding
                                    onClick={() => { navigate(item.type); }}

                                >
                                    <ListItemButton>
                                        <ListItemIcon>
                                            ➡️
                                        </ListItemIcon>
                                        <ListItemText primary={item.title} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </nav>
                </Box>
            </div>
        </div>
    );
}
