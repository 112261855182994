import { useEffect, useState } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { request } from "./request";

import Grid from '@mui/material/Grid';

import "./message.css";

export default function App() {

  useEffect(() => {
    document.title = '我想对你说｜青榄社';
  }, []);


  const options = {
    // Fullscreen: {
    //   autoStart: true,
    // },
    Carousel: {
      infinite: true,
    },
    Slideshow: {
      playOnStart: true,
      timeout: 5000,
    },
    Images: {
      zoom: false,
    },
    Toolbar: {
      enabled: true,
      items: {
        write: {
          tpl: `<button class="f-button" style="width: max-content;
            background-color: #323232;
            padding: 10pt;">邀请你也留下想对ta说的话✏️</button>`,
          click: () => {
            window.open('https://qinglanspace.feishu.cn/share/base/form/shrcnxBDprtara0hpYStsD2t5Md');
          },
        },
      },
      display: {
        right: ["write", "close"],
      },
    },
    Thumbs: false
  };

  const itemData = [
    { type: 1, img: 'https://cos.qinglan.space/message-2.png', title: '爸妈，我想对你们说......' },
    { type: 2, img: 'https://cos.qinglan.space/message-1.png', title: '朋友，我想对你说......' },
    { type: 3, img: 'https://cos.qinglan.space/message-3.png', title: '搭档，我想对你说......' },
    { type: 4, img: 'https://cos.qinglan.space/message-4.png', title: '家人，我想对你说......' },
  ]

  const [msg, setMsg] = useState("");
  const handleClick = (type: number) => {
    let fancybox: NativeFancybox;

    request(`/message/card?type=${type}`).then(async (response) => {
      const { message } = await response.json();

      if (message && message.message) {
        setMsg(message.message);
        fancybox = new NativeFancybox(
          [{ src: "#message-content", type: "inline" }] as any,
          { ...options },
        );
      }
    });
  }

  return (
    <div className="App message-bg">
      <div
        id="message-content"
        style={{
          display: "none",
          width: "100%",
          height: "100%",
          background:
            "url(https://cos.qinglan.space/mesage-bg.pic.jpg) no-repeat center center",
          backgroundSize: 'contain',
          top: 0,
          left: 0,
          maxWidth: 430
        }}
      >
        <div style={{
          display: "-webkit-flex",
          width: "100%",
          justifyContent: "center",
          fontFamily: "REEJI-Xiaodou-SeaGB-Flash-Regular",
          position: "fixed",
          top: '30%',
          left: 0,
          height: "100%",
          color: '#8D8785',
          fontSize: '.8rem'
        }}>
          我想对你说
        </div>
        <div
          style={{
            display: "-webkit-flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "REEJI-Xiaodou-SeaGB-Flash-Regular",
          }}
        >
          <div style={{ width: "80%", fontSize: '.8rem', color: '#000', marginBottom: '10vh', textAlign: 'center' }}>
            {msg?.length > 0 && <>
              <h2>{msg}</h2>
            </>}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "-webkit-flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{ paddingTop: '12vh' }}>
          <Grid container justifyContent="center" rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            {itemData.map((item) => (
              <Grid item xs={6} onClick={() => handleClick(item.type)} style={{ cursor: "pointer" }}>
                <img
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  alt={item.title}
                  loading="lazy"
                  style={{ maxWidth: '40vw', maxHeight: '16vh' }}
                />
                <p style={{ fontSize: '.6rem', margin: 0 }}>{item.title}</p>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div >
  );
}
