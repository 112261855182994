import React, { useEffect, useState } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

interface DateSelectProps {
    value: { month: number; day: number; year: number };
    onChange: (newValue: { month: number; day: number; year: number }) => void;
}

const DateSelect: React.FC<DateSelectProps> = ({ value, onChange }) => {
    const months = [
        { value: 1, label: "1月" },
        { value: 2, label: "2月" },
        { value: 3, label: "3月" },
        { value: 4, label: "4月" },
        { value: 5, label: "5月" },
        { value: 6, label: "6月" },
        { value: 7, label: "7月" },
        { value: 8, label: "8月" },
        { value: 9, label: "9月" },
        { value: 10, label: "10月" },
        { value: 11, label: "11月" },
        { value: 12, label: "12月" },
    ];

    const [defaultValue, setDefaultValue] = useState(value);

    const getDaysInMonth = (month: number, year: number) => {
        return new Date(year, month, 0).getDate();
    };

    const handleChange = (
        event: React.ChangeEvent<{ value: unknown }>,
        type: string,
    ) => {
        const newValue = { ...defaultValue, [type]: event.target.value as number };
        if (type === "month") {
            const daysInMonth = getDaysInMonth(newValue.month, newValue.year);
            if (newValue.day > daysInMonth) {
                newValue.day = daysInMonth;
            }
        }
        setDefaultValue(newValue);
        onChange(newValue);
    };

    const daysInMonth = getDaysInMonth(defaultValue.month, defaultValue.year);
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);

    return (
        <div className="select-container">
            <Select
                value={defaultValue.month}
                onChange={(e: any) => handleChange(e, "month")}
            >
                {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                        {month.label}
                    </MenuItem>
                ))}
            </Select>
            <Select value={defaultValue.day} onChange={(e: any) => handleChange(e, "day")}>
                {days.map((day) => (
                    <MenuItem key={day} value={day}>
                        {day}日
                    </MenuItem>
                ))}
            </Select>
        </div>
    );
};

export default DateSelect;
