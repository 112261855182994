import { useEffect, useState } from "react";
import "./birthday.home.css";
import DateSelect from './DateSelect';
import { Button } from '@mui/material';

export default function App(props: { onChange: (arg0: { month: number; day: number; year: number; }) => void; }) {
    const [value, setValue] = useState<any>({ month: 1, day: 1, year: 2020 })

    return (
        <div className="App home-bg">
            <div style={{
                display: '-webkit-flex',
                width: '100%', height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{ display: 'block' }}>
                    <DateSelect onChange={(v) => { setValue(v) }} value={value}></DateSelect>
                    <div style={{ margin: '1rem' }}>
                        <Button onClick={() => { props?.onChange(value) }} color="primary" size="large" variant="contained">打开</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}
