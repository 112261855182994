import "./birthday.css";
import { useEffect, useState } from "react";
import { Fancybox as NativeFancybox } from "@fancyapps/ui";
import Home from './BirthdayHome';
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import { request } from "./request";
import { Button } from "@mui/material";

export default function App() {

  useEffect(() => {
    document.title = '送你365个祝福｜青榄社';
  }, []);

  const handleWrite = () => {
    window.open('https://qinglanspace.feishu.cn/share/base/form/shrcnHeb6u92yXpTBEEYMGfOKvf');
  }

  const [inputDate, setDate] = useState("");

  const options = {
    // Fullscreen: {
    //   autoStart: true,
    // },
    Carousel: {
      infinite: true,
    },
    Slideshow: {
      playOnStart: true,
      timeout: 5000,
    },
    Images: {
      zoom: false,
    },
    Toolbar: {
      enabled: true,
      items: {
        write: {
          tpl: `<button class="f-button" style="width: fit-content;
            background-color: #323232;
            padding: 10pt;">✏️留下祝福</button>`,
          click: handleWrite,
        },
      },
      display: {
        right: ["write", "close"],
      },
    },
    Thumbs: false,
  };

  const [message, setMessage] = useState<any>({});

  useEffect(() => {
    let fancybox: NativeFancybox;
    if (inputDate) {
      request(`/birthday/card?date=${inputDate}`).then(async (response) => {
        const { cards } = await response.json();

        if (cards && cards.length > 0) {
          NativeFancybox.close();

          const card = cards[Math.floor(Math.random() * cards.length)];

          if (!!card.url) {
            fancybox = new NativeFancybox(
              [
                {
                  src: card.url.replace('https://birthday-1311310093.cos.ap-guangzhou.myqcloud.com/', 'https://cos.qinglan.space/'),
                  caption: `来自同是${inputDate.replace('-', '月')}日生日的 ${card.nickname}`,
                },
              ] as any,
              { ...options },
            );
          } else {
            setMessage({ text: card.message, from: card.nickname });
            fancybox = new NativeFancybox(
              [{ src: "#message-content", type: "inline" }] as any,
              { ...options },
            );
          }
        } else {
          // const fancybox = new NativeFancybox(
          //   [
          //     {
          //       src: "https://cos.qinglan.space/birthday-no.pic.jpg",
          //     },
          //   ] as any,
          //   options,
          // );
          fancybox = new NativeFancybox(
            [{ src: "#write-content", type: "inline" }] as any,
            { ...options },
          );
        }
      });
    }
  }, [inputDate]);

  const handleChange = (v: any) => {
    setDate(`${v.month > 9 ? v.month : '0' + v.month}-${v.day > 9 ? v.day : '0' + v.day}`)
  }

  return (
    <div className="App home-bg">
      <div
        id="message-content"
        style={{
          display: "block",
          width: "100vw",
          height: "100vh",
          background:
            "url(https://cos.qinglan.space/birthday-bg.pic.jpg) no-repeat center center",
          backgroundSize: 'contain',
          top: 0,
          left: 0,
        }}
      >
        <div
          style={{
            display: "-webkit-flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "REEJI-Xiaodou-SeaGB-Flash-Regular",
          }}
        >
          <div className="card-text">
            {message?.text?.length > 0 && <>
              <p>{message.text}</p>
              <p style={{ textAlign: 'right' }}>—— 同是{inputDate.replace('-', '月')}日生日的 {message.from}</p>
            </>}
          </div>
        </div>
      </div>
      <div
        id="write-content"
        style={{
          display: "block",
          width: "100vw",
          height: "100vh",
          background:
            "url(https://cos.qinglan.space/birthday-no.pic.jpg) no-repeat center center",
          backgroundSize: 'contain',
          top: 0,
          left: 0,
        }}
      >
        <div
          style={{
            display: "-webkit-flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            fontFamily: "REEJI-Xiaodou-SeaGB-Flash-Regular",
          }}
        >
          <a href="https://qinglanspace.feishu.cn/share/base/form/shrcnHeb6u92yXpTBEEYMGfOKvf"
            style={{ fontSize: '1rem', color: '#fff', textDecoration: 'none', padding: '1rem', background: '#716bf3', borderRadius: '0.3rem' }}
          >✏️留下祝福</a>
        </div>
      </div>
      <Home onChange={handleChange} ></Home>
    </div>
  );
}
